import React from "react"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={{ display: "Flex" }}>
      <div style={{ padding: "3vw", flex: "50%" }}>
        <h1>Alexander Hunt</h1>
        <p style={{ fontSize: 40 }}>I'm a full stack developer.</p>
        <p style={{ fontSize: 20 }}>
          I love building fun and exciting new technologies that the world has
          never seen.
        </p>
        <div>
          <p style={{ fontSize: 20, marginTop: 100, color: "gray" }}>
            More to come soon...
          </p>
        </div>
      </div>
      <div style={{ flex: "50%" }}>
        <Image />
      </div>
    </div>
  </Layout>
)

export default IndexPage
